"use client";

import Link from "next/link";
import Image from "next/image";
import { CalendarIcon, MapPinIcon } from "@heroicons/react/20/solid";
import { getDayAndMonth } from "@utils/getDayAndMonth";
import { useWindowSize } from "@utils/hooks";
import { AnimatePresence, motion } from "framer-motion";
import { useState } from "react";

interface Props {
  event: {
    Name: string;
    Url: string;
    Header: string;
    Region: string | null;
    Date: { Start: string | null; End: string | null; Range: string | null };
    Images: { BannerSmall: string; DefaultSmall: string; Portrait: string };
  };
  imageIsPriority?: boolean;
}

export default function EventLink({ event, imageIsPriority = false }: Props) {
  const windowSize = useWindowSize();
  const [hovering, setHovering] = useState(false);

  return (
    <Link
      data-testid={`event-${event.Url}`}
      href={event.Url}
      onMouseEnter={(e) => {
        e.preventDefault();
        setHovering(true);
      }}
      onMouseLeave={(e) => {
        e.preventDefault();
        setHovering(false);
      }}
      className="relative flex flex-col gap-1 hover:bg-indigo-50/75 p-2 w-full rounded-2xl transition-colors ease-in-out duration-500"
    >
      <div className="relative">
        <Image
          src={
            windowSize.width && windowSize.width > 640
              ? event.Images.Portrait.trim()
              : event.Images.BannerSmall.trim()
          }
          alt={event.Name}
          width={192}
          height={windowSize.width && windowSize.width > 640 ? 224 : 96}
          priority={imageIsPriority}
          className="object-cover w-full aspect-[2/1] sm:aspect-[6/7] rounded-xl"
        />
        {event.Date.Range ? (
          <motion.div
            layout
            className="hidden sm:flex gap-1 items-center absolute bottom-2 right-2 ml-2 p-1 rounded-lg text-sm bg-indigo-100 text-indigo-800"
          >
            <CalendarIcon className="h-5 w-5 shrink-0 p-0.5" />
            <AnimatePresence>
              {hovering && (
                <motion.span
                  initial="hide_date"
                  animate="show_date"
                  exit="hide_date"
                  variants={{
                    hide_date: {
                      display: "none",
                      width: 0,
                      height: 0,
                      opacity: 0,
                      transition: {
                        width: { delay: 0.2 },
                        height: { delay: 0.2 },
                        display: { delay: 0.45 },
                      },
                    },
                    show_date: {
                      display: "block",
                      width: "auto",
                      height: "auto",
                      opacity: 1,
                      transition: { opacity: { delay: 0.3 } },
                    },
                  }}
                  className="pointer-events-none will-change-transform"
                >
                  {event.Date.Range}
                </motion.span>
              )}
            </AnimatePresence>
          </motion.div>
        ) : event.Date.Start ? (
          <motion.div
            layout
            className="hidden sm:flex gap-1 items-center absolute bottom-2 right-2 ml-2 p-1 rounded-lg text-sm bg-indigo-100 text-indigo-800"
          >
            <CalendarIcon className="h-5 w-5 shrink-0 p-0.5" />
            <AnimatePresence>
              {hovering && (
                <motion.span
                  initial="hide_date"
                  animate="show_date"
                  exit="hide_date"
                  variants={{
                    hide_date: {
                      display: "none",
                      width: 0,
                      height: 0,
                      opacity: 0,
                      transition: {
                        width: { delay: 0.2 },
                        height: { delay: 0.2 },
                        display: { delay: 0.45 },
                      },
                    },
                    show_date: {
                      display: "block",
                      width: "auto",
                      height: "auto",
                      opacity: 1,
                      transition: { opacity: { delay: 0.3 } },
                    },
                  }}
                  className="pointer-events-none will-change-transform"
                >
                  {getDayAndMonth(
                    event.Date.Start,
                    event.Date.End &&
                      event.Date.Start.split("T")[0] !==
                        event.Date.End.split("T")[0]
                      ? {
                          day: "numeric",
                          month: "short",
                          timeZone:
                            Intl.DateTimeFormat().resolvedOptions().timeZone,
                        }
                      : {
                          day: "numeric",
                          month: "short",
                          year: "numeric",
                          timeZone:
                            Intl.DateTimeFormat().resolvedOptions().timeZone,
                        }
                  )}
                  {event.Date.End &&
                  event.Date.Start.split("T")[0] !==
                    event.Date.End.split("T")[0] ? (
                    <>
                      {" "}
                      to{" "}
                      {getDayAndMonth(event.Date.End, {
                        day: "numeric",
                        month: "short",
                        year: "numeric",
                        timeZone:
                          Intl.DateTimeFormat().resolvedOptions().timeZone,
                      })}
                    </>
                  ) : (
                    <></>
                  )}
                </motion.span>
              )}
            </AnimatePresence>
          </motion.div>
        ) : (
          <></>
        )}
      </div>
      <p className="text-lg font-semibold text-black">{event.Name}</p>
      <p className="text-gray-500">{event.Header}</p>
      {event.Date.Range ? (
        <span className="flex sm:hidden gap-0.5 items-center p-1 rounded-lg w-fit text-sm bg-indigo-100 text-indigo-800 max-w-full">
          <CalendarIcon className="h-4 w-4 shrink-0" />
          <span className="max-w-[calc(100%-18px)] truncate">
            {event.Date.Range}
          </span>
        </span>
      ) : event.Date.Start ? (
        <span className="flex sm:hidden gap-0.5 items-center p-1 rounded-lg w-fit text-sm bg-indigo-100 text-indigo-800 max-w-full">
          <CalendarIcon className="h-4 w-4 shrink-0" />
          <span className="max-w-[calc(100%-18px)] truncate">
            {getDayAndMonth(
              event.Date.Start,
              event.Date.End &&
                event.Date.Start.split("T")[0] !== event.Date.End.split("T")[0]
                ? {
                    day: "numeric",
                    month: "short",
                    timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                  }
                : {
                    day: "numeric",
                    month: "short",
                    year: "numeric",
                    timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                  }
            )}
            {event.Date.End &&
            event.Date.Start.split("T")[0] !== event.Date.End.split("T")[0] ? (
              <>
                {" "}
                to{" "}
                {getDayAndMonth(event.Date.End, {
                  day: "numeric",
                  month: "short",
                  year: "numeric",
                  timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                })}
              </>
            ) : (
              <></>
            )}
          </span>
        </span>
      ) : (
        <></>
      )}
      {event.Region ? (
        <span className="flex gap-0.5 items-center p-1 rounded-lg w-fit text-sm bg-gray-100 text-gray-800 max-w-full">
          <MapPinIcon className="h-4 w-4" />
          <span className="max-w-[calc(100%-18px)] truncate">
            {event.Region}
          </span>
        </span>
      ) : (
        <></>
      )}
    </Link>
  );
}
