import { COUNTRY_CODE } from "./constants";

export const getDayAndMonth = (
  string: string,
  options: Intl.DateTimeFormatOptions = {
    day: "numeric",
    month: "short",
    timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
  }
) => {
  const date = new Date(string);
  return date.toLocaleDateString("en-NZ", options);
};

/**
 * Convert a UTC date format to a user's local timezome
 * @param utcDateTime
 * @param options
 * @returns
 */
export const convertUTCToLocalTime = (
  utcDateTime?: string,
  options: Intl.DateTimeFormatOptions = {
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
  }
) => {
  const nzDate = new Date(getUTCDate(utcDateTime));
  return nzDate.toLocaleString(undefined, options);
};

export const getUTCDate = (utcDateTime?: string) => {
  const utcDate = utcDateTime ? new Date(utcDateTime) : new Date();
  const offSet = utcDate.getTimezoneOffset() * 60000; //convert minutes to hours

  return utcDate.getTime() - offSet;
};
/**
 * Convert a date string to NZ local time
 *
 * @param string
 * @param options
 * @returns
 */
export const getDateWithoutTimezone = (
  string?: string,
  options: Intl.DateTimeFormatOptions = {
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
  }
) => {
  const date = string ? new Date(string) : new Date();

  return date.toLocaleDateString(
    COUNTRY_CODE === "au" ? "en-AU" : "en-NZ",
    options
  );
};

export const getTimeSinceDate = (
  string: string | number | Date,
  today = new Date()
) => {
  const date = new Date(string);
  const seconds = Math.max(
    Math.floor((today.getTime() - date.getTime()) / 1000),
    0
  );
  let timeSince = "";

  if (Math.floor(seconds / 31536000)) {
    timeSince = `${Math.floor(seconds / 31536000)} ${
      Math.floor(seconds / 31536000) > 1 ? "years" : "year"
    } ago`;
  } else if (Math.floor(seconds / 2592000)) {
    timeSince = `${Math.floor(seconds / 2592000)} ${
      Math.floor(seconds / 2592000) > 1 ? "months" : "month"
    } ago`;
  } else if (Math.floor(seconds / 604800)) {
    timeSince = `${Math.floor(seconds / 604800)} ${
      Math.floor(seconds / 604800) > 1 ? "weeks" : "week"
    } ago`;
  } else if (Math.floor(seconds / 86400)) {
    timeSince = `${Math.floor(seconds / 86400)} ${
      Math.floor(seconds / 86400) > 1 ? "days" : "day"
    } ago`;
  } else if (Math.floor(seconds / 3600)) {
    timeSince = `${Math.floor(seconds / 3600)} ${
      Math.floor(seconds / 3600) > 1 ? "hours" : "hour"
    } ago`;
  } else if (Math.floor(seconds / 60)) {
    timeSince = `${Math.floor(seconds / 60)} ${
      Math.floor(seconds / 60) > 1 ? "minutes" : "minute"
    } ago`;
  } else {
    timeSince = `${seconds} ${seconds !== 1 ? "seconds" : "second"} ago`;
  }

  return timeSince;
};
